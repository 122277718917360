// Mensagens customizadas para o Pristine
Pristine.addMessages("en", {
  required: "Este campo é obrigatório.",
  email: "Por favor, forneça um endereço de e-mail válido.",
  number: "Por favor, forneça um número válido.",
  integer: "Por favor, forneça apenas dígitos.",
  url: "Por favor, forneça um URL válido.",
  tel: "Por favor, forneça um número de telefone válido.",
  maxlength: "Por favor, forneça menos do que ${1} caracteres.",
  minlength: "Por favor, forneça mais do que ${1} caracteres.",
  min: "O valor mínimo para este campo é de ${1}.",
  max: "O valor máximo para este campo é de ${1}.",
  pattern:
    "Por favor, forneça uma informação de acordo com o formato do campo.",
  equals: "Os dois campos não correspondem.",
  default: "Por favor, forneça um valor válido.",
});

Pristine.addValidator(
  "cnpj",
  (value) => {
    return cnpjValidation(value);
  },
  "Por favor, forneça um CNPJ válido.",
  2,
  false
);

Pristine.addValidator(
  "cpf",
  (value) => {
    return cpfValidation(value);
  },
  "Por favor, forneça um CPF válido.",
  2,
  false
);

// Referência: https://gist.github.com/alexbruno/6623b5afa847f891de9cb6f704d86d02
function cnpjValidation(value) {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const isString = typeof value === "string";
  const validTypes =
    isString || Number.isInteger(value) || Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Filtro inicial para entradas do tipo string
  if (isString) {
    // Limita ao máximo de 18 caracteres, para CNPJ formatado
    if (value.length > 18) return false;

    // Teste Regex para veificar se é uma string apenas dígitos válida
    const digitsOnly = /^\d{14}$/.test(value);
    // Teste Regex para verificar se é uma string formatada válida
    const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

    // Se o formato é válido, usa um truque para seguir o fluxo da validação
    if (digitsOnly || validFormat) true;
    // Se não, retorna inválido
    else return false;
  }

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Valida a quantidade de dígitos
  if (numbers.length !== 14) return false;

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

// Referência: https://gist.github.com/alexbruno/cfb9f33cd49434bde9a40cfa6743e675
function cpfValidation(value) {
  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes =
    typeof value === "string" ||
    Number.isInteger(value) ||
    Array.isArray(value);

  // Elimina valores com formato inválido
  if (!validTypes) return false;

  // Guarda todos os dígitos em um array
  const numbers = value.toString().match(/\d/g).map(Number);

  // Valida quantidade de dígitos
  if (numbers.length !== 11) return false;

  // Elimina valores inválidos com todos os dígitos repetidos
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Separa número base do dígito verificador
  const base = numbers.slice(0, 9);
  const digits = numbers.slice(9);

  // Cálculo base
  const calc = (n, i, x) => n * (x - i);

  // Utilitário de soma
  const sum = (r, n) => r + n;

  // Cálculo de dígito verificador
  const digit = (n) => {
    const rest = n % numbers.length;
    return rest < 2 ? 0 : numbers.length - rest;
  };

  // Cálculo sobre o número base
  const calc0 = base
    .map((n, i) => calc(n, i, numbers.length - 1))
    .reduce(sum, 0);
  // 1o. dígito verificador
  const digit0 = digit(calc0);

  // Valida 1o. digito verificador
  if (digit0 !== digits[0]) return false;

  // Cálculo sobre o número base + 1o. dígito verificador
  const calc1 = base
    .concat(digit0)
    .map((n, i) => calc(n, i, numbers.length))
    .reduce(sum, 0);
  // 2o. dígito verificador
  const digit1 = digit(calc1);

  // Valida 2o. dígito verificador
  return digit1 === digits[1];
}

let btnMenuMobile = false;
const menuController = {
  isDesktop: window.matchMedia("(min-width:1200px)").matches,
  eventType: "mouseover",

  init() {
    this.eventType = this.isDesktop ? "mouseover" : "click";

    // Menu padrão
    if (this.isDesktop) {
      this.openMenu();
    } else {
      this.openMenuMobile();
    }

    this.openSubMenu();

    // Menu área (cliente e parceiro)
    this.openMenuArea();
    this.openSubMenuArea();

    // Fecha o menu quando clica em qualquer lugar da página fora do container.
    this.closeMenu();

    // Abre painel de pesquisa
    this.searchMobile();
    this.searchDesktop();
  },

  openMenu() {
    let btnMenu = true;
    const btnOpenMenu = document.querySelectorAll(".js-open-menu");
    const allMenuContainer = document.querySelectorAll(".js-menu-container");

    btnOpenMenu.forEach((button) => {
      button.addEventListener(this.eventType, ({ currentTarget }) => {
        let siblingElement = currentTarget.nextElementSibling;
        let hasContainer =
          siblingElement.classList.contains("js-menu-container");

        // EM DESKTOP, PERMITE SOMENTE UM CONTAINER DE MENU ABERTO POR VEZ.
        allMenuContainer.forEach((containerMenu) => {
          if (siblingElement !== containerMenu) {
            containerMenu.classList.remove("open");
          }
        });
        if (btnMenu) {
          siblingElement.classList.add("open");
          btnMenu = false;
          // console.log('btnMenu true open');
        } else {
          siblingElement.classList.remove("open");
          btnMenu = true;
          // console.log('btnMenu false open');
        }

        if (siblingElement.classList.contains("open")) {
          currentTarget.setAttribute("aria-expanded", "true");
        } else {
          currentTarget.setAttribute("aria-expanded", "false");
        }
      });
    });

    btnOpenMenu.forEach((button) => {
      button.addEventListener("mouseout", ({ currentTarget }) => {
        const siblingElement = currentTarget.nextElementSibling;
        const hasContainer = siblingElement.classList.contains("open");
        // EM DESKTOP, PERMITE SOMENTE UM CONTAINER DE MENU ABERTO POR VEZ.
        allMenuContainer.forEach((containerMenu) => {
          if (siblingElement !== containerMenu) {
            containerMenu.classList.remove("open");
          }
        });
        if (!btnMenu) {
          siblingElement.classList.remove("open");
          btnMenu = true;
        }
        if (siblingElement.classList.contains("open")) {
          currentTarget.setAttribute("aria-expanded", "true");
        } else {
          currentTarget.setAttribute("aria-expanded", "false");
        }
      });
    });
    allMenuContainer.forEach((container) => {
      container.addEventListener("mouseover", ({ currentTarget }) =>
        currentTarget.classList.add("open")
      );
    });
    allMenuContainer.forEach((container) => {
      container.addEventListener("mouseout", ({ currentTarget }) =>
        currentTarget.classList.remove("open")
      );
    });
  },
  openMenuMobile() {
    const btnOpenMenu = document.querySelectorAll(".js-open-menu");
    const allMenuContainer = document.querySelectorAll(".js-menu-container");
    btnOpenMenu.forEach((button) => {
      button.addEventListener(this.eventType, ({ currentTarget }) => {
        let siblingElement = currentTarget.nextElementSibling;

        allMenuContainer.forEach((containerMenu) => {
          if (siblingElement !== containerMenu) {
            containerMenu.classList.remove("open");
          } else {
            if (
              currentTarget.getAttribute("aria-expanded", "true") &&
              siblingElement.classList.contains("open")
            ) {
              btnMenuMobile = true;
            } else {
              siblingElement.classList.add("open");
              containerMenu.classList.add("open");
              btnMenuMobile = false;
            }
          }
        });
        if (!btnMenuMobile) {
          siblingElement.classList.add("open");
          btnMenuMobile = true;
        } else {
          siblingElement.classList.remove("open");
          btnMenuMobile = false;
        }
        if (siblingElement.classList.contains("open")) {
          currentTarget.setAttribute("aria-expanded", "true");
        } else {
          currentTarget.setAttribute("aria-expanded", "false");
        }
      });
    });
    btnOpenMenu.forEach((button) => {
      button.addEventListener("mouseout", ({ currentTarget }) => {
        const siblingElement = currentTarget.nextElementSibling;
        const hasContainer = siblingElement.classList.contains("open");
        // EM DESKTOP, PERMITE SOMENTE UM CONTAINER DE MENU ABERTO POR VEZ.
        allMenuContainer.forEach((containerMenu) => {
          if (siblingElement !== containerMenu) {
            containerMenu.classList.remove("open");
          }
        });
        // if (!btnMenu) {
        //   console.log('!btnMenu true open');
        //   siblingElement.classList.remove('open')
        //   btnMenu = true;
        // }
        if (siblingElement.classList.contains("open")) {
          currentTarget.setAttribute("aria-expanded", "true");
        } else {
          currentTarget.setAttribute("aria-expanded", "false");
        }
      });
    });
    allMenuContainer.forEach((container) => {
      container.addEventListener("mouseover", ({ currentTarget }) =>
        currentTarget.classList.add("open")
      );
    });
    allMenuContainer.forEach((container) => {
      container.addEventListener("mouseout", ({ currentTarget }) =>
        currentTarget.classList.remove("open")
      );
    });
  },
  openSubMenu() {
    const subMenuTitle = document.querySelectorAll(
      ".nav-menu__submenu-item--title"
    );

    subMenuTitle.forEach((eachElement) => {
      eachElement.addEventListener("click", ({ target }) => {
        let grandParentEl = "";

        if (target.classList.contains("accordion-arrow")) {
          target.classList.toggle("accordion-arrow--active");
          grandParentEl = target.parentElement.parentElement.parentElement;
        } else {
          target.children[0]?.classList.toggle("accordion-arrow--active");
          grandParentEl = target.parentElement.parentElement;
        }

        for (const element of grandParentEl.children) {
          if (element !== subMenuTitle) {
            element.classList.toggle("open");
          }
        }
      });
    });
  },

  openMenuArea() {
    const subMenuTitle = document.querySelectorAll(
      ".header__submenu-item--title"
    );
    subMenuTitle.forEach((eachElement) => {
      eachElement.addEventListener("click", ({ target }) => {
        let grandParentEl = "";

        if (target.classList.contains("accordion-arrow")) {
          grandParentEl = target.parentElement.parentElement.parentElement;
        } else {
          grandParentEl = target.parentElement.parentElement;
        }

        if (target.classList.contains("accordion-arrow")) {
          target.classList.toggle("accordion-arrow--active");
        } else {
          target.children[0].classList.toggle("accordion-arrow--active");
        }

        for (const element of grandParentEl.children) {
          if (
            element !== subMenuTitle &&
            !element.classList.contains("header__submenu-item--third-level")
          ) {
            element.classList.toggle("open");
          }
        }
      });
    });
  },

  openSubMenuArea() {
    const openMenuList = document.querySelectorAll(
      ".header__submenu-item--third-level-open button"
    );

    openMenuList.forEach((eachElement) => {
      eachElement.addEventListener("click", ({ target }) => {
        let siblingElement = "";
        let childrenElement = "";

        if (target.classList.contains("accordion-arrow-third-level")) {
          siblingElement = target.parentElement.nextElementSibling;
          childrenElement = target;
        } else {
          siblingElement = target.nextElementSibling;
          childrenElement = target.children[0];
        }

        childrenElement.classList.toggle("accordion-arrow-third-level--active");

        for (const element of siblingElement.children) {
          if (element.classList.contains("header__submenu-item--third-level")) {
            element.classList.toggle("open");
          }
        }

        if (
          childrenElement.classList.contains(
            "accordion-arrow-third-level--active"
          )
        ) {
          target.setAttribute("aria-expanded", "true");
        } else {
          target.setAttribute("aria-expanded", "false");
        }
      });
    });
  },

  closeMenu() {
    const elementsToMatch = [
      ".js-open-menu",
      ".header__submenu-item--third-level-open > button",
      "nav-menu__submenu-item--title > button",
      ".accordion-arrow-third-level",
    ];

    document.addEventListener("click", ({ target }) => {
      if (!target.matches(elementsToMatch) && this.isDesktop) {
        const allMenuContainer =
          document.querySelectorAll(".js-menu-container");
        allMenuContainer.forEach((container) => {
          if (container.classList.contains("open")) {
            container.classList.remove("open");
          }
        });
      }
    });
  },

  searchMobile() {
    // console.log("entrei searchMobile ");
    const button = document.querySelector(".header__search-btn-mobile");
    const checkboxMenu = document.querySelector(".hamburger-menu__checkbox");
    const searchContainer = document.querySelector(
      ".header__nav-search-mobile"
    );

    button?.addEventListener("click", () => {
      searchContainer.classList.toggle("header__nav-search-mobile--open");
      document.querySelector(".header__logo").style.display = "none";
      document.querySelector(".header__search-title-mobile").style.display =
        "block";
    });

    checkboxMenu?.addEventListener("click", () => {
      if (!checkboxMenu.checked) {
        searchContainer.classList.remove("header__nav-search-mobile--open");
      }
    });
  },
  searchDesktop() {
    const checkbox = document.querySelector(".hamburger-menu-search__checkbox");
    const ul = document.querySelector(".nav-menu > ul");
    const header = document.querySelector(".search-results__form--header");

    checkbox?.addEventListener("click", () => {
      if (checkbox.checked) {
        ul.style.display = "none";
        header.style.display = "flex";
      } else {
        ul.style.display = "flex";
        header.style.display = "none";
      }
    });
  },
};

function menuAnchorLP() {
  // console.log('entrei');

  const anchorItens = document.querySelectorAll("a.nav-menu-lp__item");
  const humburger = document.querySelector("input#hamburger-menu-lp");
  if (humburger) {
    humburger.checked = false;
  }

  anchorItens.forEach((item) => {
    item.addEventListener("click", () => {
      humburger.checked = false;
    });
  });
}

// Background-color do footer igual ao da última section da página.
function setFooterBgColor() {
  const sections = document.querySelectorAll("section");
  const footer = document.querySelector("footer.footer");
  const lastSection = sections[sections.length - 1];
  const lastSectionBgColor = getComputedStyle(lastSection).backgroundColor;
  document.querySelector("footer.footer").style.backgroundColor =
    lastSectionBgColor;

  if (footer) {
    document.querySelector("footer.footer").style.backgroundColor =
      lastSectionBgColor;
  }
}

// Color do cantainer do footer de acordo com a classe escolhida pela div parametrizadora.
function setFooterContainerColor() {
  const paramClassName = document.querySelector(
    "div#param-footer-color"
  )?.className;
  document
    .querySelector("footer.footer div.footer__custom")
    ?.classList.add(paramClassName);
}

/*  O header é fixo e a tag main possui uma margin-top. 
Com isso ao clicar no menu de âncoras, o elemento com o id correspondente não 
fica totalmente visível na tela. A função abaixo foi criada para solucionar esse problema. */
function scrollToElement(elementId) {
  const element = document.getElementById(elementId);
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.pageYOffset;
  const headerHeight = document.querySelector("header.header")?.offsetHeight;
  const scrollTo = offsetPosition - headerHeight;

  window.scrollTo({
    top: scrollTo,
    behavior: "smooth",
  });
}

const anchors = document.querySelectorAll('a[href^="#"][class$="__pointer"]');

if (document.querySelector("header.header")) {
  anchors.forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const elementId = this.getAttribute("href").substring(1);
      scrollToElement(elementId);
    });
  });
}

let isMobile = false;
window.onload = function () {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    isMobile = true;
  }
};

function mobileTelefone(numero) {
  // console.log(numero);
  if (isMobile) {
    window.location.href = numero;
  }
}

function sliderAutoPlay() {
  const carousels = document.querySelectorAll(".glider-autoplay");

  window.addEventListener("load", function () {
    for (const carousel of carousels) {
      const glider = new Glider(carousel);

      function sliderAuto(slider, miliseconds) {
        const slidesCount = slider.track.childElementCount;
        let slideTimeout = null;
        let nextIndex = 1;

        function slide() {
          slideTimeout = setTimeout(function () {
            if (nextIndex >= slidesCount) {
              nextIndex = 0;
            }
            slider.scrollItem(nextIndex++);
          }, miliseconds);
        }

        slider.ele.addEventListener("glider-animated", function () {
          window.clearInterval(slideTimeout);
          slide();
        });

        slide();
      }

      sliderAuto(glider, 7000);
    }
  });
}

function enviarAreaCliente() {
  window.location.href =
    "https://financeira.safra.com.br/autenticacao-area-logada/login";
}

menuController.init();
menuAnchorLP();
setFooterBgColor();
setFooterContainerColor();
sliderAutoPlay();
